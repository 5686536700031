<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <b-card-group>
            <b-card no-body class="px-5 py-4">
              <b-card-body>
                <!-- <h1 class="mb-4">Login</h1> -->
                <img class="m-auto d-block" src="img/brand/logo.png" width="160" height="40" alt="Logo">        
                <b-row>
                    <ResponseAlert ref="response" />
                </b-row>
                <form @submit.prevent="reset" class="needs-validation mt-5" novalidate>
                  <div class="form-group" :class="{'has-error': errors.has('new password') }">
                    <div class="input-group position-relative">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="icon-lock"></i></div>
                      </div>
                      <input v-model="models.new_password" 
                        v-validate="'required'" 
                        name="new password" 
                        class="form-control" 
                        :type="vars.type.new" 
                        placeholder="New Password">
                      <div @click.prevent="togglePassword('new')" class="position-absolute" style="right: 13px; bottom: 13px; cursor: pointer; z-index: 99">
                        <i class="fa fa-eye"></i>
                      </div>
                    </div>
                    <div class="help-block" v-if="errors.has('new password')">
                      <p class="text-danger">{{ errors.first('new password') }}</p>
                    </div>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.has('password confirmation') }">
                    <div class="input-group position-relative">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="icon-lock"></i></div>
                      </div>
                      <input  
                        v-model="models.password_confirmation" 
                        v-validate="'required'" 
                        name="password confirmation" 
                        class="form-control" 
                        :type="vars.type.confirmation"
                        placeholder="Password confirmation">
                      <div @click.prevent="togglePassword('confirmation')" class="position-absolute" style="right: 13px; bottom: 13px; cursor: pointer; z-index: 99">
                        <i class="fa fa-eye"></i>
                      </div>
                    </div>
                    <div class="help-block" v-if="errors.has('password confirmation')">
                      <p class="text-danger">{{ errors.first('password confirmation') }}</p>
                    </div>
                  </div>
                  <b-row>
                    <b-col cols="12">
                      <b-button type="submit" variant="primary" class="px-4 btn-block">Send</b-button>
                    </b-col>
                  </b-row>
                </form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ResponseAlert from '@/components/response_alert';
import api from '@/utils/api.js';

export default {
  name: 'ForgotPassword',
  components: {
    ResponseAlert,
  },
  data() {
    return {
      models : {
        new_password: '',
        password_confirmation: ''
      },
      vars: {
        type: {
          new: 'password',
          confirmation: 'password'
        }
      }
    }
  },
  methods : {
    reset(){
      const _ = this;
      _.$validator.validate().then(valid => {
        if (valid) {
          _.$axios.put(api.reset_password, _.models, {
            headers: {
              'Authorization' : 'Bearer ' + _.$route.query.token
            }
          })
          .then(() => {
            this.$showToast('Success', 'Success change password')
            _.$router.push('/login')
          })
          .catch(error => _.$refs.response.setResponse(error.response.data.message, 'danger'));
        }
      });
    },
    togglePassword(type) {
      const _ = this
      _.vars.type[type] = _.vars.type[type] === 'password' ? 'text' : 'password'
    }
  }
}
</script>